<template>
  <div>
    <loading :active.sync="dados.isLoading" :can-cancel="false" :is-full-page="true" color="#f68b29" />

    <div class='botoes'>
      <b-button type="button" variant="primary" size="sm" class="btnProcessar" :disabled="dados.disabledCampos"
        @click="dados.filtro_opened = !dados.filtro_opened"><v-icon scale="0.8" name="filter" /> Filtro</b-button>
    </div>

    <div v-if="dados.filtro_opened">
      <b-form class="filtro" @submit.prevent="listarDados(1)">
        <InputBasico label="Data Inicial" name="fieldDataInicial" size="sm"
          info="Data baseada no início do processamento" v-model="dados.item.dataInicial" v-validate="{ required: true }"
          :disabled="dados.disabledCampos" min=1 max=12 type="date" />
        <InputBasico label="Data Final" name="fieldDataFinal" info="Data baseada no início do processamento" size="sm"
          v-model="dados.item.dataFinal" :disabled="dados.disabledCampos" type="date" />

        <b-button type="submit" variant="primary" size="sm" class="btnProcessar"
          :disabled="dados.disabledCampos"><v-icon scale="0.8" name="search" /> Buscar</b-button>
      </b-form>
    </div>

    <label v-if="dados.isConcluido" class="concluido loaderCheck">Concluído com sucesso!</label>

    <div class="loader">
      <Processando v-if="dados.show_loader" mensagem="Carregando dados da Base..." />
      <Processando v-if="dados.show_processing" mensagem="Processando requisição..." />
    </div>

    <div v-if="dados.show_loader !== true">
      <h5>Resultado da busca</h5>

      <div class="resultado" v-if="dados.total > 0">
        {{ dados.total > 1 ? 'Encontradas' : 'Encontrada' }} <strong>{{ dados.total }}</strong> {{ dados.total > 1 ?
          'ocorrências' :'ocorrência'}}.
      </div>

      <div v-if="dados.listaItens.length > 0">
        <b-table striped hover bordered small responsive="sm" head-variant="dark" :items="dados.listaItens"
          :fields="dados.fields" :tbody-tr-class="rowClass">

          <template #head(actions)="data">
            <center>Ações</center>
          </template>

          <template #cell(actions)="row">
            <center>
              <b-button :disabled="!row.item.parametros" class="btn btn-success btnAction" size="sm"
                @click="row.toggleDetails" title="Visualizar parâmetros de integração"><v-icon scale="1"
                  :name="row.detailsShowing ? 'clipboard' : 'clipboard-list'" /></b-button>
              <b-button :disabled="validaJson(row.item)" class="btn btn-danger btnAction" size="sm" v-b-modal.viewLog
                @click="selecionarItem(row.item)" title="Ver ocorrências de envio"><v-icon scale="1"
                  name="eye" /></b-button>
            </center>
          </template>

          <template #cell(dataInicioProcessamento)="row">
            {{ retornaDataFormatadaTabelaConsulta(row.item.dataInicioProcessamento) }}
          </template>

          <template #cell(dataFimProcessamento)="row">
            {{ retornaDataFormatadaTabelaConsulta(row.item.dataFimProcessamento) }}
          </template>

          <template #row-details="row">
            <b-card class="detalhe">
              <span>Parâmetros</span>

              <b-row class="sm-2" v-if="row.item.parametros.tipo">
                <b-col sm="2" class="text-sm-right"><b>Tipo:</b></b-col>
                <b-col>{{ row.item.parametros.tipo }}</b-col>
              </b-row>

              <b-row class="sm-2" v-if="row.item.parametros.date_low">
                <b-col sm="2" class="text-sm-right"><b>Data Inicial:</b></b-col>
                <b-col>{{ row.item.parametros.date_low }}</b-col>
              </b-row>

              <b-row class="sm-2" v-if="row.item.parametros.date_high">
                <b-col sm="2" class="text-sm-right"><b>Data Final:</b></b-col>
                <b-col>{{ row.item.parametros.date_high }}</b-col>
              </b-row>

              <b-row class="sm-2" v-if="row.item.parametros.branch && row.item.parametros.branch.length > 0">
                <b-col sm="2" class="text-sm-right"><b>Filiais:</b></b-col>
                <b-col>{{ arrayParaString(row.item.parametros.branch, "filial") }}</b-col>
              </b-row>

              <b-row class="sm-2" v-if="row.item.parametros.listaDocnum && row.item.parametros.listaDocnum.length > 0">
                <b-col sm="2" class="text-sm-right"><b>Documentos:</b></b-col>
                <b-col>{{ arrayParaString(row.item.parametros.listaDocnum, "docnum") }}</b-col>
              </b-row>

            </b-card>
          </template>
        </b-table>

        <div id="footerTable">
          <div>
            <b-form-select v-model="paginacao.limitePorPagina" size="sm" :options="paginacao.limitOptions"
              @change="listarDados(1)"></b-form-select>
          </div>

          <div class="paginacao" v-if="dados.listaItens.length > 0">
            <b-pagination class="paginacao__seletor" :total-rows="paginacao.totalDePaginas"
              :per-page="paginacao.limitePorPagina" v-model="paginacao.paginaAtual" @input="mudarPagina" size="sm" />
          </div>
        </div>
      </div>
      <div v-else>
        Nenhum registro encontrado.
      </div>
    </div>

    <b-modal id="jsonErro" title="Estrutura de envio - erros" size="xl">
      <div>
        <vue-json-pretty :showLine="true" :showDoubleQuotes="true" :highlightMouseoverNode="true"
          :collapsedOnClickBrackets="true" :data="dados.jsonErro">
        </vue-json-pretty>
      </div>
    </b-modal>

    <b-modal id="viewLog" title="Ocorrências de integração" size="xl">
      <div>
        <b-tabs small content-class="mt-3">
          <b-tab title="Notas" active>
            <template #title>
              Notas <b-spinner small label="Small Spinner" type="grow" variant="danger"
                v-if="dados.itemSelecionado.retornoNota.length > 0" />
            </template>
            <b-table striped hover bordered head-variant="dark" stacked="md" small
              v-if="dados.itemSelecionado.retornoNota.length > 0" :items="dados.itemSelecionado.retornoNota"
              :fields="dados.fieldsNotas">
            </b-table>
            <span v-if="dados.itemSelecionado.retornoNota.length <= 0">Nenhuma ocorrência encontrada</span>
          </b-tab>
          <b-tab title="Produtos">
            <template #title>
              Produtos <b-spinner small label="Small Spinner" type="grow" variant="danger"
                v-if="dados.itemSelecionado.retornoProduto.length > 0" />
            </template>
            <b-table striped hover small bordered head-variant="dark"
              v-if="dados.itemSelecionado.retornoProduto.length > 0" :items="dados.itemSelecionado.retornoProduto"
              :fields="dados.fieldsProdutos"></b-table>
            <span v-if="dados.itemSelecionado.retornoProduto.length <= 0">Nenhuma ocorrência encontrada</span>
          </b-tab>
          <b-tab title="Materiais">
            <template #title>
              Materiais <b-spinner small label="Small Spinner" type="grow" variant="danger"
                v-if="dados.itemSelecionado.retornoMaterial.length > 0" />
            </template>
            <b-table striped hover small bordered head-variant="dark"
              v-if="dados.itemSelecionado.retornoMaterial.length > 0" :items="dados.itemSelecionado.retornoMaterial"
              :fields="dados.fieldsMaterial"></b-table>
            <span v-if="dados.itemSelecionado.retornoMaterial.length <= 0">Nenhuma ocorrência encontrada</span>
          </b-tab>
          <b-tab title="NaoIntegradas">
            <template #title>
              Notas Não Integradas <b-spinner small label="Small Spinner" type="grow" variant="danger"
                v-if="dados.itemSelecionado.retornoEstruturacao.length > 0" />
            </template>
            <b-table striped hover small bordered head-variant="dark"
              v-if="dados.itemSelecionado.retornoEstruturacao.length > 0"
              :items="dados.itemSelecionado.retornoEstruturacao" :fields="dados.fieldsEstruturacao"></b-table>
            <span v-if="dados.itemSelecionado.retornoEstruturacao.length <= 0">Nenhuma ocorrência encontrada</span>
          </b-tab>
          <b-tab title="Participantes">
            <template #title>
              Participantes <b-spinner small label="Small Spinner" type="grow" variant="danger"
                v-if="dados.itemSelecionado.retornoParticipante.length > 0" />
            </template>
            <b-table striped hover bordered head-variant="dark" stacked="md" small
              v-if="dados.itemSelecionado.retornoParticipante.length > 0"
              :items="dados.itemSelecionado.retornoParticipante" :fields="dados.fieldsParticipantes">
            </b-table>
            <span v-if="dados.itemSelecionado.retornoParticipante.length <= 0">Nenhuma ocorrência encontrada</span>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { getLogProcessamentoNotas } from '../../../common/services/logsService';
import InputBasico from '../../../components/inputs/InputBasico';
import Processando from '../../../components/comuns/Processando';
import * as dataHelper from '../../../common/helpers/DataHelper';
import * as arrayHelper from '../../../common/helpers/ArrayHelper';
import VueJsonPretty from 'vue-json-pretty';

export default {
  data() {
    return {
      dados: {
        empresa: null,
        isLoading: true,
        item: {
          dataInicial: undefined,
          dataFinal: undefined
        },
        notaEnvioView: {},
        jsonErro: {},
        disabledCampos: false,
        filtro_opened: true,
        data_loaded: false,
        show_loader: false,
        show_processing: false,
        listaItens: [],
        itemSelecionado: {
          retornoNota: [],
          retornoProduto: [],
          retornoParticipante: [],
          retornoMaterial: [],
          retornoEstruturacao: []
        },
        fields: [
          { key: "dataInicioProcessamento", label: "Inicio Processamento", sortable: true },
          { key: "dataFimProcessamento", label: "Fim Processamento", sortable: true },
          { key: "actions", label: "Ações", sortable: true }
        ],
        fieldsProdutos: [
          { key: "codEmp", label: "Código empresa", sortable: true },
          { key: "codPro", label: "Código produto", sortable: true },
          { key: "desRet", label: "Descrição retorno", sortable: true }
        ],
        fieldsParticipantes: [
          { key: "cgcCpf", label: "CPF/CNPJ", sortable: true },
          { key: "codCli", label: "Código cliente", sortable: true },
          { key: "codFor", label: "Código de fornecedor", sortable: true },
          { key: "ideExt", label: "Identificação externa", sortable: true },
          { key: "retorno", label: "Descrição retorno", sortable: true }
        ],
        fieldsEstruturacao: [
          { key: "nota.docnum", label: "ID Oracle", sortable: true },
          { key: "nota.nfenum", label: "Nota", sortable: true },
          { key: "nota.formulario_nota", label: "Série", sortable: true },
          { key: "mensagem", label: "Erro", sortable: true }
        ],
        fieldsNotas: [
          { key: "retornoImportacao.result.gridRetorno.codEmp", label: "Emp.", sortable: true },
          { key: "retornoImportacao.result.gridRetorno.codFil", label: "Filial", sortable: true },
          { key: "retornoImportacao.result.gridRetorno.numNfi", label: "Nº NF", sortable: true },
          { key: "retornoImportacao.result.gridRetorno.codSnf", label: "Série", sortable: true },
          { key: "retornoImportacao.result.gridRetorno.msgRet", label: "Mensagem retorno", sortable: true }
        ],
        fieldsMaterial: [
          { key: "n_material", label: "Cód. Material", sortable: true },
          { key: "erro", label: "Mensagem", sortable: false }
        ]
      },
      paginacao: {
        paginaAtual: 1,
        limitePorPagina: 10,
        totalDePaginas: 0,
        limitOptions: [
          { value: 10, text: '10 por página' },
          { value: 25, text: '25 por página' },
          { value: 50, text: '50 por página' },
          { value: 75, text: '75 por página' },
          { value: 100, text: '100 por página' }
        ]
      }
    }
  },
  components: {
    InputBasico,
    Processando,
    VueJsonPretty
  },
  methods: {
    async listarDados(pagina) {
      if (this.validaData()) {
        this.dados.listaItens = [];
        this.dados.show_loader = true;
        this.dados.disabledCampos = true;

        const filtro = {};

        this.dados.item.idCliente = this.dados.empresa;

        Object.keys(this.dados.item).forEach((key) => {
          if (this.dados.item[key]) {
            filtro[key] = this.dados.item[key];
          }
        });

        filtro.limite = this.paginacao.limitePorPagina,
          filtro.pagina = pagina;

        const resultado = (await getLogProcessamentoNotas(filtro)).data;
        this.dados.listaItens = resultado.list;
        this.dados.total = resultado.total;
        this.paginacao.totalDePaginas = parseInt(this.paginacao.limitePorPagina * resultado.pages);
      }

      this.dados.show_loader = false;
      this.dados.disabledCampos = false;
      this.dados.isLoading = false;
    },
    async mudarPagina(p) {
      const { paginaAtual } = this.paginacao;
      this.listarDados(paginaAtual);
    },
    retornaDataFormatadaTabelaConsulta(data) {
      return dataHelper.retornaISOParaStringComMascara(data, 'DD/MM/YYYY - HH:mm:ss')
    },
    abrirEnvioSapiens(envio) {
      this.dados.notaEnvioView = envio
    },
    async buscaErrosJson(ArrayIn) {

      let tempJson = {};
      tempJson._id = ArrayIn._id;
      tempJson.idCliente = ArrayIn.idCliente;
      tempJson.retornoEstruturacao = [];

      if (ArrayIn.retornoEstruturacao !== null && ArrayIn.retornoEstruturacao.length > 0) {

        ArrayIn.retornoEstruturacao.forEach(e => {
          var tempRetEstrut = {};
          if (e && e.nota) {
            tempRetEstrut.nota = e.nota.docnum;
            tempRetEstrut.materiais = [];
            if (e.nota.materiais && e.nota.materiais.length > 0) {
              e.nota.materiais.forEach(a => {
                if (!a.tp_material) {
                  a.erro = 'Valor de "tp_material" está em branco';
                  tempRetEstrut.materiais.push(a);
                }
              });
            }

            if (tempRetEstrut && tempRetEstrut.materiais.length > 0) {
              tempJson.retornoEstruturacao.push(tempRetEstrut);
            }
          }
        });
      }

      return tempJson;
    },
    validaJson(ArrayIn) {
      let r = [];
      let ret = true;

      // Valida se existe erros em estrutura
      if (ArrayIn.retornoEstruturacao && ArrayIn.retornoEstruturacao.length > 0) ret = false;

      // Valida se existe erros em notas
      if (ArrayIn.retornoNota && ArrayIn.retornoNota.filter(f => f != null).length > 0) ret = false;

      // Valida se existe erros em cadastro de produto
      if (ArrayIn.retornoProduto && ArrayIn.retornoProduto.length > 0) {
        ArrayIn.retornoProduto.forEach((f) => {
          if (f && f.retorno) {
            if (!Array.isArray(f.retorno)) {
              f.retorno = [f.retorno]
            }
            r.push(...f.retorno?.filter(p => p != null && p.desRet !== "OK"));
          }
        });
        if (r.length > 0) ret = false;
      }

      if (ArrayIn.retornoEstruturacao && ArrayIn.retornoEstruturacao.length > 0) {
        ArrayIn.retornoEstruturacao.forEach(e => {
          if (e && e.nota.materiais && e.nota.materiais.length > 0) {
            e.nota.materiais.forEach(a => {
              if (!a.tp_material) {
                ret = false;
              }
            })
          }
        })
      }

      // Valida se existe erros em participantes
      if (ArrayIn.retornoParticipante && ArrayIn.retornoParticipante.length > 0) ret = false;

      return ret;
    },
    rowClass(item, type) {
      if (item && type === 'row') {
        if (!this.validaJson(item)) {
          return "table-danger"
        }
      }
    },
    async mostraArrayErros(ArrayIn) {
      const ret = await this.buscaErrosJson(ArrayIn);
      if (ret.retornoEstruturacao) {
        this.dados.jsonErro = ret;
      }
    },
    selecionarItem(json) {
      let r = [];

      this.dados.itemSelecionado = {
        retornoNota: [],
        retornoProduto: [],
        retornoParticipante: [],
        retornoMaterial: [],
        retornoEstruturacao: []
      }

      this.dados.itemSelecionado.retornoNota = json.retornoNota.filter((f) => f !== null);

      json.retornoProduto.forEach((f) => {
        if (f && f.retorno) {
          r.push(...f.retorno.filter(p => p != null && p.desRet !== "OK"));
        }
      });

      this.dados.itemSelecionado.retornoProduto = r;

      if (json.retornoFornecedor != null)
        this.dados.itemSelecionado.retornoParticipante.push(...json.retornoFornecedor.filter((f) => f !== null));
      if (json.retornoCliente != null)
        this.dados.itemSelecionado.retornoParticipante.push(...json.retornoCliente.filter((f) => f !== null));
      if (json.retornoParticipante != null)
        this.dados.itemSelecionado.retornoParticipante.push(...json.retornoParticipante.filter((f) => f !== null));
      if (json.retornoEstruturacao != null)
        this.dados.itemSelecionado.retornoEstruturacao.push(...json.retornoEstruturacao.filter((f) => f !== null));

      r = [];
      json.retornoEstruturacao.forEach(f => {
        const campos = ['tp_material', 'item_sped', 'n_material', 'descricao_material', 'cod_controle', 'unidade_medida', 'txt_un_medida'];
        if (f != null) {
          r.push(...f.nota.materiais.filter((m) => {
            if (m !== null && campos.filter(x => m[x] == "").length > 0) {
              let temp = m;
              temp.erro = "";
              campos.forEach(x => {
                if (temp[x] == "") {
                  temp.erro = temp.erro + 'O campo "' + x + '" não pode estar em branco. '
                }
              });
              return temp;
            }
          }));
        }
      });
      this.dados.itemSelecionado.retornoMaterial = r;
    },
    arrayParaString(array, field) {
      return arrayHelper.arrayParaString(array, field);
    },
    validaData() {
      const d1 = new Date(this.dados.item.dataInicial + 'T00:00:00');
      const d2 = new Date(this.dados.item.dataFinal + 'T23:59:00');

      if (!this.dados.item.dataInicial || !this.dados.item.dataFinal) {
        alert('É necessário selecionar um período.');
        return false;
      }

      if (d1 > d2) {
        alert('A data inicial deve ser menor que a data final.');
        return false;
      }

      return true;
    }
  },
  async created() {
    const local = JSON.parse(await localStorage.getItem('_cdi'));
    this.dados.empresa = local.empresa.id;

    let df = new Date(Date.now());
    let di = new Date();
    di.setDate(df.getDate() - 30);

    this.dados.item.dataInicial = dataHelper.retornaDataYYYYMMDD(di);
    this.dados.item.dataFinal = dataHelper.retornaDataYYYYMMDD(df);

    this.listarDados(1);
  }
}
</script>

<style lang="scss" scoped>
@import "@/common/scss/breakpoints.scss";

.filtro {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $medium-size) {
    width: 100%;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 0.8em;
  }
}
</style>